import React, { Component, useContext } from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import Loader from "../../components/loader"

import ExcercisesProUser from "../../components/page-components/excercises-prouser"
import { colors } from "../../components/siteVars"
import ExcercisesCoach from "../../components/page-components/excercises-coach"
import ExcercisesAdmin from "../../components/page-components/excercises-admin"
import ExcercisesUser from "../../components/page-components/excercises-user"
import { GlobalStateContext } from "../../context/GlobalContextProvider"
import { navigate } from "gatsby"

const LoaderContainer = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ExcercisesPage = () => {
  const state = useContext(GlobalStateContext)

  let content = (
    <LoaderContainer>
      <Loader dualRing color={colors.mediumGrey} />
    </LoaderContainer>
  )
  if (state.user.permissions) {
    if (state.user.permissions === "user") {
      content = <ExcercisesUser />
    }
    if (state.user.permissions === "proUser") {
      content = <ExcercisesProUser />
    }
    if (state.user.permissions === "coach") {
      content = <ExcercisesCoach />
    }
    if (state.user.permissions === "admin") {
      content = <ExcercisesAdmin />
    }
  } else {
    // content = navigate("/login")
  }
  return <Layout>{content}</Layout>
}

export default ExcercisesPage
