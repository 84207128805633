import React, { Component } from "react"
import styled from "styled-components"
import { colors, db } from "../siteVars"
import axios from "axios"
import Loader from "../loader"
import Excercise from "../excercise"
import Searchbar from "../searchbar"
import Contentbox from "../contentbox"

const StyledExcercisesProUser = styled.div`
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem; */
  .excercises-container {
    margin-top: 2rem;
  }
  .loader-container {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h1 {
    margin-top: 0;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    /* padding: 0 1rem; */
  }
`

export default class ExcercisesProUser extends Component {
  state = {
    excercises: [],
    loading: true,
    filteredExcercises: [],
  }
  componentDidMount() {
    axios
      .get(db.lambda + "excercises/")
      .then(res => {
        this.setState({
          excercises: res.data,
          loading: false,
        })
      })
      .catch(err => console.log(err))
  }

  returnFunction = array => {
    this.setState({
      filteredExcercises: array,
    })
  }

  render() {
    return (
      <StyledExcercisesProUser>
        {this.state.loading ? (
          <div className="loader-container">
            <Loader dualRing color={colors.darkGrey} />
          </div>
        ) : (
          <div>
            <Contentbox>
              <h1>Excercises</h1>
              <Searchbar
                context="excercises"
                array={this.state.excercises}
                returnFunction={this.returnFunction}
              />
            </Contentbox>
            <div className="excercises-container">
              {this.state.filteredExcercises.map((excercise, index) => (
                <Excercise
                  altKey={excercise._id}
                  key={excercise._id}
                  excercise={excercise}
                  index={index}
                  regular
                />
              ))}
            </div>
          </div>
        )}
      </StyledExcercisesProUser>
    )
  }
}
