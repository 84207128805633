import React, { Component } from "react"
import axios from "axios"
import popupAlert from "../popup-alert"
import styled from "styled-components"
import Excercise from "../excercise"
import { colors, db, categories } from "../siteVars"
import Loader from "../loader"
import Iframe from "../iframe"
import Searchbar from "../searchbar"
import Insta from "../insta"
import Contentbox from "../contentbox"
import Button from "../button"
import Input from "../input"
import Textarea from "../textarea"
import Select from "../select"
import Cross from "../cross"
import TagBubble from "../tag-bubble"

const StyledExcercisesAdmin = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto auto;
  grid-gap: 2rem;
  align-items: flex-start;
  h1 {
    margin-top: 0;
  }
  .create-excercise-button-container {
    margin-top: 1rem;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  form {
    display: flex;
    flex-direction: column;

    button {
      &.preview-button {
        margin-right: 0.5rem;
      }
      &.edit-abort-button {
        margin-left: 0.5rem;
      }
    }
  }
  .loader-container {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tags-container {
    display: flex;
    flex-wrap: wrap;
  }
`

export default class ExcercisesAdmin extends Component {
  state = {
    allTags: [],
    excercises: [],
    filteredExcercises: [],
    title: "",
    description: "",
    image: "",
    video: "",
    instaVideo: "",
    loading: true,
    topCat: categories.top[0],
    middleCat: categories.middle[0].value,
    bottomCat: categories.bottom[0].value,
    preview: false,
    edit: false,
    excerciseId: "",
    tags: "",
  }

  componentDidMount() {
    this.fetchExcercises()
  }

  getTags = () => {
    let tags = []
    this.state.excercises.forEach(excercise => {
      excercise.tags.forEach(tag => {
        if (tags.indexOf(tag) === -1) {
          tags.push(tag)
        }
      })
    })
    this.setState({
      allTags: tags,
    })
  }

  fetchExcercises = () => {
    this.setState({
      loading: true,
    })
    axios
      .get(db.lambda + "excercises/")
      .then(res => {
        this.setState({
          excercises: res.data,
          loading: false,
        })
        this.getTags()
      })
      .catch(err => console.log(err))
  }

  deleteExcercise = id => {
    axios.delete(db.lambda + "excercises/" + id).then(res => {
      console.log(res.data)

      this.fetchExcercises()
    })
  }

  onSubmit = e => {
    e.preventDefault()
    let tags = this.state.tags.split(",")
    tags.forEach(tag => {
      tag.trim()
    })

    const excercise = {
      title: this.state.title,
      description: this.state.description,
      image: this.state.image,
      video: this.state.video,
      instaVideo: this.state.instaVideo,
      topCat: this.state.topCat,
      middleCat: this.state.middleCat,
      bottomCat: this.state.bottomCat,
      tags: tags,
    }

    console.log(excercise)

    axios.post(db.lambda + "excercises/add", excercise).then(res => {
      console.log(res.data)
      popupAlert("Excercise added!")
      this.fetchExcercises()
      this.setState({
        title: "",
        difficulty: 0,
        image: "",
        video: "",
        instaVideo: "",
        topCat: categories.top[0],
        middleCat: categories.middle[0].value,
        bottomCat: categories.bottom[0].value,
        tags: "",
        preview: false,
      })
    })
  }

  togglePreview = () => {
    this.setState({
      preview: !this.state.preview,
    })
  }

  onChange = e => {
    switch (e.target.name) {
      case "topCat":
        const filteredMiddle = categories.middle.filter(
          item => item.parent === e.target.value
        )
        const filteredBottom = categories.bottom.filter(
          item => item.parent === categories.middle[0].value
        )
        this.setState({
          topCat: e.target.value,
          middleCat: filteredMiddle[0].value,
          bottomCat: filteredBottom[0].value,
        })
        break
      case "middleCat":
        const filtered = categories.bottom.filter(
          item => item.parent === e.target.value
        )
        this.setState({
          bottomCat: filtered[0].value,
          middleCat: e.target.value,
        })
        break
      case "bottomCat":
        this.setState({
          bottomCat: e.target.value,
        })
        break
      default:
        this.setState({
          [e.target.name]: e.target.value,
        })
        break
    }
  }

  editInit = excercise => {
    this.setState({
      edit: true,
      title: excercise.title,
      description: excercise.description,
      image: excercise.image,
      video: excercise.video,
      instaVideo: excercise.instaVideo,
      topCat: excercise.topCat,
      middleCat: excercise.middleCat,
      bottomCat: excercise.bottomCat,
      excerciseId: excercise._id,
    })
  }

  abortEdit = () => {
    this.setState({
      edit: false,
      title: "",
      description: "",
      image: "",
      video: "",
      instaVideo: "",
      topCat: categories.top[0],
      middleCat: categories.middle[0].value,
      bottomCat: categories.bottom[0].value,
      excerciseId: "",
    })
  }

  submitEdit = e => {
    e.preventDefault()
    const excercise = {
      title: this.state.title,
      description: this.state.description,
      image: this.state.image,
      video: this.state.video,
      instaVideo: this.state.instaVideo,
      topCat: this.state.topCat,
      middleCat: this.state.middleCat,
      bottomCat: this.state.bottomCat,
      preview: false,
    }

    console.log(excercise)

    axios
      .post(
        db.lambda + "excercises/update/" + this.state.excerciseId,
        excercise
      )
      .then(res => {
        console.log(res.data)
        this.setState({
          edit: false,
          title: "",
          description: "",
          image: "",
          video: "",
          instaVideo: "",
          topCat: categories.top[0],
          middleCat: categories.middle[0].value,
          bottomCat: categories.bottom[0].value,
          excerciseId: "",
        })
        popupAlert("Excercise updated!")
        this.fetchExcercises()
      })
  }

  returnFunction = res => {
    this.setState({
      filteredExcercises: res,
    })
  }

  addTag = tag => {
    this.setState({
      tags: this.state.tags + "," + tag,
    })
  }

  render() {
    return (
      <StyledExcercisesAdmin>
        <Contentbox className="content-left">
          <h1>{this.state.edit ? "Edit" : "Create"} excercise</h1>
          <form onSubmit={this.state.edit ? this.submitEdit : this.onSubmit}>
            {/* <p>Title</p> */}
            <Input
              label="Title"
              type="text"
              name="title"
              placeholder="Title"
              value={this.state.title}
              onChange={this.onChange}
              required
            />
            <Textarea
              placeholder="Desctiption"
              label="Description"
              type="text"
              name="description"
              value={this.state.description}
              onChange={this.onChange}
              rows="5"
              required
            />

            <Input
              label="Paste image link"
              type="text"
              value={this.state.image}
              placeholder="Image"
              name="image"
              onChange={this.onChange}
            />
            <Input
              label="Paste youtube embedd code"
              type="text"
              name="video"
              value={this.state.video}
              placeholder="Youtube video"
              onChange={this.onChange}
            />
            {/* <Input
              placeholder="Instagram video"
              type="text"
              name="instaVideo"
              value={this.state.instaVideo}
              label="Paste instagram link"
              onChange={this.onChange}
            /> */}
            <div className="categories-container">
              <Select
                value={this.state.topCat}
                label="Top category"
                name="topCat"
                onChange={this.onChange}
              >
                {categories.top.map((cat, index) => (
                  <option key={cat + index} value={cat}>
                    {cat}
                  </option>
                ))}
              </Select>
              <Select
                label="Middle category"
                value={this.state.middleCat}
                name="middleCat"
                onChange={this.onChange}
              >
                {categories.middle.map((cat, index) => (
                  <React.Fragment key={cat.value + cat.parent + index}>
                    {cat.parent === this.state.topCat ? (
                      <option value={cat.value}>{cat.value}</option>
                    ) : null}
                  </React.Fragment>
                ))}
              </Select>
              <Select
                label="Bottom category"
                value={this.state.bottomCat}
                name="bottomCat"
                onChange={this.onChange}
              >
                {categories.bottom.map((cat, index) => (
                  <React.Fragment key={cat.value + cat.parent + index}>
                    {cat.parent === this.state.middleCat &&
                    cat.topParent === this.state.topCat ? (
                      <option value={cat.value}>{cat.value}</option>
                    ) : null}
                  </React.Fragment>
                ))}
              </Select>
              <Input
                type="text"
                onChange={this.onChange}
                value={this.state.tags}
                name="tags"
                label="Separate tags by comma"
                placeholder="Tags"
              />
              <div className="tags-container">
                {this.state.allTags.map(tag => (
                  <TagBubble
                    onClick={() => {
                      this.addTag(tag)
                    }}
                    key={tag}
                  >
                    {tag}
                  </TagBubble>
                ))}
              </div>
            </div>
            <div className="create-excercise-button-container">
              <Button
                className="preview-button"
                onClick={() => {
                  this.togglePreview()
                }}
                type="button"
              >
                {this.state.preview ? "Close" : "Preview"}
              </Button>
              <Button className="submit-button" type="submit" color="green">
                {this.state.edit ? "Update" : "Create"}
              </Button>
              {this.state.edit ? (
                <Button
                  circle
                  color="red"
                  className="edit-abort-button"
                  type="button"
                  onClick={() => {
                    this.abortEdit()
                  }}
                >
                  <Cross color={colors.white} />
                </Button>
              ) : null}
            </div>
          </form>
        </Contentbox>
        <div className="content-right">
          {this.state.loading ? (
            <div className="loader-container">
              <Loader dualRing color={colors.darkGrey} />
            </div>
          ) : (
            <div className="excercise-list-container">
              {this.state.preview ? (
                <Contentbox className="excercise-preview">
                  <h1>Preview</h1>
                  <h3>{this.state.title}</h3>
                  <p>{this.state.description}</p>
                  <img
                    src={this.state.image}
                    alt={this.state.title}
                    width="100%"
                  />
                  {this.state.video[0] === "<" ? (
                    <Iframe>{this.state.video}</Iframe>
                  ) : null}
                  {this.state.instaVideo ? (
                    <Insta link={this.state.instaVideo} />
                  ) : null}
                </Contentbox>
              ) : null}
              <Contentbox>
                <h1>All excercises</h1>
                <Searchbar
                  returnFunction={this.returnFunction}
                  array={this.state.excercises}
                  context="excercises"
                />
              </Contentbox>
              {this.state.filteredExcercises.length > 0 ? (
                <>
                  {this.state.filteredExcercises.map((excercise, index) => (
                    <Excercise
                      altKey={excercise._id}
                      key={excercise._id}
                      editInit={this.editInit}
                      excercise={excercise}
                      deleteExcercise={this.deleteExcercise}
                      index={index}
                    />
                  ))}
                </>
              ) : (
                <Contentbox>
                  <h3 style={{ margin: 0 }}>
                    No excercises matched your search
                  </h3>
                </Contentbox>
              )}
            </div>
          )}
        </div>
      </StyledExcercisesAdmin>
    )
  }
}
