import React, { Component } from "react"
import styled from "styled-components"
import { colors } from "./siteVars"

const StyledTagBubble = styled.div`
  p {
    padding: 0.5rem;
    margin: 0.5rem;
    background: ${colors.blue};
    color: ${colors.white};
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
  }
`

export default class TagBubble extends Component {
  render() {
    return (
      <StyledTagBubble onClick={this.props.onClick}>
        <p>{this.props.children}</p>
      </StyledTagBubble>
    )
  }
}
